/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.error-text {
	color: red;
	padding: 5px;
}

.mat-form-no-border-top {
	.mat-form-field-infix {
		border-top: none;
	}
}